import {Component, Provide, Vue, Watch,} from 'vue-property-decorator';
import common from '../../Common/Index.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {GetOrderProcessReq, SubmitEvaluateReq} from "@/interface/res/mine";
import {GetOrderDetailByIdApi, SubmitEvaluateApi} from "@/network/modules/mine";
import {File} from "@/interface/pagas/common";


@Component({
  components: {
    common,
    ElImageViewer
  },
  computed: {
  }
})
export default class Comment extends Vue {
  @Provide() id:Array<number> = []
  @Provide() orderNo:string = ''
  @Provide() loading:boolean = false
  @Provide() cusOrderDetail:any = {}
  @Provide() scoreForm:{
    installationServices:number,
    logisticsServices:number,
    productRating:number,
    serviceAttitude:number,
  } = {
    installationServices:0,
    logisticsServices:0,
    productRating:0,
    serviceAttitude:0,
  }
  @Provide() form:SubmitEvaluateReq = {
    evaluatorContent:"",
    installationServices:0,
    logisticsServices:0,
    productRating:0,
    serviceAttitude:0,
    orderDetailId:-1,
    nickName:"",
  }
  @Provide() msg:string = ''
  @Provide() uploadLoading:boolean = false;
  @Provide() uploadTime:any = null;
  @Provide() installImgs:Array<string> = [];
  @Provide() installImgsTotal:number = 0;
  @Provide() installImgsMax:number = 6;
  @Provide() checked:boolean = false;
  @Provide() btnLoading:boolean = false;

  @Provide() showBig:boolean = false;
  @Provide() showBigImgList:Array<any> = [];
  @Provide() showBigImgListIndex:number = 0;

  @Watch('scoreForm',{immediate:false, deep:true})
  private iListChange(newVal:SubmitEvaluateReq):void {
    const _this:any = this;
    _this.form.installationServices = newVal.installationServices * 2
    _this.form.logisticsServices = newVal.logisticsServices * 2
    _this.form.productRating = newVal.productRating * 2
    _this.form.serviceAttitude = newVal.serviceAttitude * 2
  }



  created(){
    const _this:any = this;
    window.scrollTo({top:0})
    if(!_this.$base.isNull(_this.$route.query.i)){
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.form.orderDetailId = _this.id
      _this.GetOrderDetailByIdFn()
    }

  }


  /**
   * @Author HS
   * @Date 2021/8/25 4:26 下午
   * @Description: 评论
   * @Params: null
   * @Return: null
  */
  async SubmitEvaluateFn(){
    const _this:any = this;

    if(_this.uploadLoading)return

    if(_this.$base.isNull(_this.form.evaluatorContent)){
      _this.msg = '请输入评论内容'
      return
    }
    _this.msg = ''

    if(_this.checked){
      _this.form.nickName = '匿名'
    }
    else{
      _this.form.nickName = _this.$store.state.user.userInfo.nickName
    }

    _this.form.evaluatorUrls = JSON.stringify(_this.installImgs)

    _this.btnLoading = true
    let params:SubmitEvaluateReq = {..._this.form}
    const res = await SubmitEvaluateApi(params)
    let a = setTimeout(()=>{_this.btnLoading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }

    _this.$message({
      message: '已评论',
      type: 'success',
      duration:1500,
      onClose:()=>{
        _this.$router.go(-1)
      }
    });

  }

  /**
   * @Author HS
   * @Date 2021/8/23 4:41 下午
   * @Description: 删除图片
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  deleteImgFn(index:number){
    const _this:any = this;
    _this.$refs['image'].uploadFiles.splice(index,1)
    _this.installImgs.splice(index,1)
    _this.installImgsTotal = _this.installImgs.length
  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  imgClick(item:any,index:number) {
    const _this:any = this;
    _this.showBigImgList = [..._this.installImgs]
    _this.showBigImgListIndex = index
    _this.showBig = true;
  }

  /**
   * @Author HS
   * @Date 2021/8/23 5:26 下午
   * @Description: 打开上传
   * @Params: null
   * @Return: null
   */
  uploadImgFn(){
    const _this:any = this;
    _this.$refs['image'].$children[0].$refs.input.click()
  }
  /**
   * @Author HS
   * @Date 2021/7/26 5:50 下午
   * @Description: 图片上传
   * @Params: {object : response - true}[上传返回信息]
   * @Return: null
   */
  imageSuccess(response:File){
    const _this:any = this;
    _this.installImgs.push(response.data)
    clearTimeout(_this.uploadTime)
    _this.uploadTime = setTimeout(()=>{
      _this.installImgsTotal = _this.installImgs.length
      _this.uploadLoading = false
      clearTimeout(_this.uploadTime)
    },2000)
  }
  /**
   * @Author HS
   * @Date 2021/7/28 3:30 下午
   * @Description: 图片上传超出上线
   * @Params: null
   * @Return: null
   */
  imageExceed(){
    const _this:any = this;
    _this.$message.error("一次最多发送6张图片");
  }
  /**
   * @Author HS
   * @Date 2021/8/24 11:21 上午
   * @Description: 图片上传前
   * @Params: null
   * @Return: null
   */
  imageBeforeUpload(){
    const _this:any = this;
    _this.uploadLoading = true
  }

  /**
   * @Author HS
   * @Date 2021/8/25 11:32 上午
   * @Description: 定单产品详情
   * @Params: null
   * @Return: null
   */
  async GetOrderDetailByIdFn(){
    const _this:any = this;
    _this.loading = true;
    let params:GetOrderProcessReq = {
      orderDetailId:_this.id
    }
    const res = await GetOrderDetailByIdApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code, msg, data } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    const { cusOrderDetail, orderNo } = data
    _this.cusOrderDetail = cusOrderDetail
    _this.orderNo = orderNo
  }

  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: null
   * @Return: null
   */
  goDetails(){
    const _this:any = this;
    if(_this.cusOrderDetail.commoditySeries == 2)return;
    let link = ''
    if(_this.cusOrderDetail.commoditySeries == 0){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${_this.cusOrderDetail.commoditySeries}&i=`+_this.cusOrderDetail.commodityId)
    }
    if(_this.cusOrderDetail.commoditySeries == 1){
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path,`type=${_this.cusOrderDetail.commoditySeries}&i=`+_this.cusOrderDetail.commodityId)
    }
    _this.$base.goRouter(link)
  }

}



